<template>
  <router-link :to="{name: 'tournaments_id', params: {id: getTournamentId}}" class="sgl-tournament-card">
    <img class="sgl-tournament-card--bg" :src="getBackground" alt="#">
    <div class="sgl-tournament-card--content">
      <div class="sgl-tournament-card--label sgl-label" :class="{'sgl-label_show': getStatusRegistration}">Открыта
        регистрация
      </div>
      <div class="sgl-tournament-card--title">{{ getTournamentName }}</div>

      <div class="sgl-tournament-card--prizeFund prizeFund">
        <span class="prizeFund__mainTitle">Призовой фонд:</span>
        <span class="prizeFund__secondTitle">{{ getPrizeFund }}</span>
      </div>

      <div class="sgl-tournament-card--row">
        <div class="info">
          <img class="sgl-tournament-card--discipline-logo"
               :src="getIconOfGameDiscipline"
               alt="#">

          <div class="info-block">
            <div v-if="!getStatusInProcess && isTimer" class="info-block-row">
              <span class="info-block-row-mainTitle">начнётся через:</span>
              <span class="info-block-row-secondTitle">
                <count-down-timer
                    :seconds="getTournamentStartDistance(tournament)"
                    @ended="updateTimerStatus()"
                />
              </span>
            </div>

            <div class="info-block-row">
              <span class="info-block-row-mainTitle">Участники:</span>
              <span
                  class="info-block-row-secondTitle">{{ getCurrentCountOfParticipants }} / {{ getAllCountOfParticipants }}</span>
            </div>
          </div>
        </div>

        <template v-if="!tournamentCardHaveActiveEntry">
          <div v-if="getStatusInProcess" class="sgl-button sgl-button--empty">Уже идет</div>
          <div v-if="getStatusRegistration"
               class="sgl-button sgl-button--fill-colored"
               @click.stop="participateInTournament(tournament, $event)"
          >
            Принять участие
          </div>
        </template>
        <div v-else class="sgl-button sgl-button--empty" style="pointer-events: none">Вы участвуете</div>
      </div>
    </div>

    <simple-text-modal
        v-if="isSimpleModalOpen"
        :configForSimpleTextModal="configForSimpleTextModal"
        @closeSimpleModal="isSimpleModalOpen = false"
    />
  </router-link>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import getTimezone from "@/otherJS/getTimezone";
import {tournamentInfoMixin} from "@/otherJS/mixins/tournamentInfoMixin";
import SimpleTextModal from "@/components/modals/simple-text-modal";
import TournamentRegistration from "@/components/modals/tournament-registration/tournament-registration";
import CountDownTimer from "@/components/CountDownTimer/CountDownTimer";

export default {
  name: "TournamentMainEventCard",
  components: {CountDownTimer, SimpleTextModal},
  data() {
    return {
      isSimpleModalOpen: false,
      isModalOpened: false,
      currentComposition: null,
      showRegBtn: true,
      isTimer: true,
    }
  },

  props: {
    tournament: Object
  },
  mixins: [getTimezone, tournamentInfoMixin],

  methods: {
    updateTimerStatus() {
      this.isTimer = false;
    }
  },

  computed: {
    ...mapGetters([
      'USER',
      'GET_SPONSOR_GAMES',
      'GAMES',
      'TOURNAMENT',
      'GET_USER_IS_AUTHENTICATE'
    ]),

    getCurrentStamp() {
      return this.tournament.attributes['current_timestamp']
    },

    getBackground() {
      return this.tournament.background
    },

    getTournamentId() {
      return this.tournament.id
    },

    getTournamentName() {
      return this.tournament.attributes.name
    },

    getIconOfGameDiscipline() {
      const gameId = this.tournament.relationships.game.data.id;
      return this.GET_SPONSOR_GAMES.find((game) => String(game.id) === String(gameId))?.logo || '';
    },

    getCurrentCountOfParticipants() {
      return this.tournament.attributes['participants_count']
    },

    getStartedAt() {
      return this.tournament.attributes['started_at']
    },

    getAllCountOfParticipants() {
      return this.tournament.attributes['count_of_teams']
    },

    getPrizeFund() {
      return this.tournament.attributes['prize_fund']
    },

    getStatusInProcess() {
      return this.tournament.attributes.status === 'in_process'
    },

    getStatusRegistration() {
      return this.tournament.attributes.status === "registration"
    },

    getEndTime() {
      return (moment(this.getStartedAt).unix());
    }
  },
  mounted() {
    this.setIsActiveParticipantEntry(this.tournament);
  }
}
</script>

<style scoped>

</style>